
import { Component, Mixins, Prop } from "vue-property-decorator";
import { AthleteRoutingMixin, BAIconsMixin, StringsMixin, VuetifyMixin, HelpInfoMixin } from "@/mixins";
import { VideoClipModel } from '@/models/video/VideoClipModel';
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';
import ClipPlayerStudio from '@/components/videoClipPlayer/ClipPlayerStudio.vue';
import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import { HostedVideoDetail } from "@/../types";

@Component({
    components: {
		VideoThumbnail,
        ClipPlayerStudio,
        VideoLibraryProvider,
    }
})
export default class AthleteHighlightReelCard extends Mixins(VuetifyMixin, BAIconsMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin) {
    @Prop({type: Boolean}) flat;
    @Prop({type: Boolean}) buttons;
    @Prop({type: Boolean}) single;
    @Prop({type: Boolean}) hideText;
    @Prop({type: String, default:'50'}) size;
    @Prop({type: String, required: true}) athleteId;
    @Prop({default: null}) sharingUrlId: string | null;
    @Prop() highlightDetails: HostedVideoDetail[];
    @Prop() fullmatchDetails: HostedVideoDetail[];

	$refs: {
		highlightClipPlayer: ClipPlayerStudio,
		videoClipPlayer: ClipPlayerStudio,
	};

    get HasVideoLinks(): boolean {
        return this.HasHighlightLinks || this.HasFullMatchLinks;
    }
    get HasHighlightLinks(): boolean {
        if( this.IsEmptyArray(this.highlightDetails) ) return false;
        return( this.IsNotEmpty(this.highlightDetails[0].videoUrl) );
    }

    get HasFullMatchLinks(): boolean {
        if( this.IsEmptyArray(this.fullmatchDetails) ) return false;
        return( this.IsNotEmpty(this.fullmatchDetails[0].videoUrl) );
    }

    HasVideoHighlights(clips: VideoClipModel[]): boolean {
        return (clips.length > 0) || this.HasVideoLinks;
    }
	highlightReelPlayerVisible: boolean = false;
	currentClip: VideoClipModel | null = null;
	playAll() {
		this.highlightReelPlayerVisible = true;
		this.$refs.highlightClipPlayer.playAll();
	}
    playClip(clip: VideoClipModel) {
        this.highlightReelPlayerVisible = true;
        this.$refs.highlightClipPlayer.playClip(clip)
    }
    playVideo(video: HostedVideoDetail) {
        window.open( video.videoUrl, "_blank" );
    }
}
