
import { VuetifyMixin, BAIconsMixin, AppHostnameMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin, CoachRoutingMixin } from '@/mixins';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { AthleteProfileModel } from '@/models';
import { AuthMixin, ScoutingReportMixin } from '@/mixins';
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import ScoutingScoreSummary from "@/components/scoutingReport/ScoutingScoreSummary.vue"
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import RadarChartJS from "@/components/charts/RadarChartJS.vue";
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';

@Component({
	components: {
		ScoutingReportScoreChip,
		ScoutingScoreSummary,
		RecruitingProfileInfo,
		RadarChartJS,
		SubscriptionInfoProvider
	}
})
export default class AthleteScoutingReportsCard extends Mixins(AuthMixin, AppHostnameMixin, BAIconsMixin, StringsMixin, VuetifyMixin, ScoutingReportMixin, AthleteRoutingMixin, CoachRoutingMixin, HelpInfoMixin){
	@Prop() scoutingReport: ScoutingReportModel;
	@Prop() athleteProfile: AthleteProfileModel;
	@Prop({ type: Boolean }) flat;
	@Prop({ type: Boolean }) buttons;
	@Prop({ type: Boolean }) history;
	@Prop({ type: Boolean }) extended;

	searchScoutingReports = ""
	expanded: []
	scoutingReportHeaders: Array<DataTableHeader> = [
		{ text: 'Date', value: 'date', align: 'center', sortable: true},
		{ text: 'Location', value: 'location', filterable: true, sortable: false},
		{ text: 'Performance', value: 'performance', align: 'center', sortable: true},
		{ text: 'Potential', value: 'potential', align: 'center', sortable: true},
		{ text: '4 Corners', value: 'corners.score', align: 'center', sortable: false},
		{ text: '6 Moments', value: 'moments.score', align: 'center', sortable: false},
		{ text: 'C.R.I.S.P', value: 'crisp.score', align: 'center', sortable: false},
		{ text: '', value: 'actions', align: 'center', sortable: false},
		{ text: '', value: 'data-table-expand', sortable: false},
	]

	
	updatingFeatured: boolean = false;
	get HasScoutingReport(): boolean {
		return this.IsNotEmpty(this.scoutingReport);
	}
	get HasAthleteProfile(): boolean {
		return this.IsNotEmpty(this.athleteProfile);
	}

	IsBlurred(isFreePlan: boolean): boolean {
		if( this.CoachAppActive ) return this.public;
		return this.public || isFreePlan;
	}
}
