
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { PerformanceCategory, getPerformanceCategoryValues } from '@/../types/enums';
import ProgressBar from '@/components/charts/ProgressBar.vue';
import { performanceCategoryToLabel, performanceCategoryToColor } from '@/pipes';
import { MetricData } from '@/../types/interfaces/MetricData';

interface PerformanceCategoryData{
	level: PerformanceCategory,
	label: string,
	color: string,
	html: string,
	progress: number,
}
@Component({
	components: {
		ProgressBar,
	}
})
export default class PerformanceLevelPreview extends Mixins(VuetifyMixin){
	@Prop({ default: null }) category: PerformanceCategory | null;
	@Prop({ default: null }) data: MetricData | null;
	@Prop({ type: Boolean, default: false }) hideDetails: boolean;
	@Prop({ type: Boolean, default: false }) over18: boolean;

	get Ready(): boolean{
		return this.PerformanceLevel !== undefined;
	}

	get SlotProps(): Record<string, any>{
		return {
			...this.PerformanceLevel,
			Ready: this.Ready,
		}
	}
	get HasData(): boolean {
		if( !this.data ) return false;
		return !this.data.missing;
	}
	get PerformanceLevel(): PerformanceCategoryData{
		// data has not been measured in this category
		if( !this.HasData ) {
			return {
				level: PerformanceCategory.Recreational,
				label: performanceCategoryToLabel(null),
				color: performanceCategoryToColor(null),
				html: "No measurement has been recorded for this metric.",
				progress: 33,
			}
		}
		// data has been measured but category not determined as it is below all levels
		if( this.category == null ) {
			// level: unranked
			return {
				level: PerformanceCategory.Recreational,
				label: performanceCategoryToLabel(null),
				color: performanceCategoryToColor(null),
				html: 'This is a beginner level in the sport. Start playing the sport to improve or have fun.',
				progress: 33,
			};
		}

		let level = this.PerformanceLevels.find(p => p.level === this.category);
		if( !level ) {
			if( this.category === PerformanceCategory.Professional ) this.category = PerformanceCategory.ProAcademy;
			else if( this.category === PerformanceCategory.ProAcademy ) this.category = PerformanceCategory.Professional;
			else if( this.category === PerformanceCategory.HighPerformance ) this.category = PerformanceCategory.College;
			else if( this.category === PerformanceCategory.College ) this.category = PerformanceCategory.HighPerformance;
			level = this.PerformanceLevels.find(p => p.level === this.category);
		}

		return { ...level };
	}

	get PerformanceCategories(): PerformanceCategory[]{
		return getPerformanceCategoryValues(this.over18);
	}
	get PerformanceLevels(): PerformanceCategoryData[]{
		const categories = this.PerformanceCategories;
		return this.performanceLevels.filter(l => categories.findIndex(p => p === l.level) !== -1)
	}

	performanceLevels: PerformanceCategoryData[] = [
		{
			level: PerformanceCategory.Recreational,
			label: performanceCategoryToLabel(PerformanceCategory.Recreational),
			color: performanceCategoryToColor(PerformanceCategory.Recreational),
			html: 'This is a non-competitive level of sport. You play for fun, to see and be with your friends and make new ones, and to stay in shape.',
			progress: 25,
		},
		{
			level: PerformanceCategory.Competitive,
			label: performanceCategoryToLabel(PerformanceCategory.Competitive),
			color: performanceCategoryToColor(PerformanceCategory.Competitive),
			html: 'This is a competitive level of sport. You have been selected into a team based on your talent and skill, and you compete with and against your peers in a league where scores and standings are kept.',
			progress: 40,
		},
		{
			level: PerformanceCategory.HighPerformance,
			label: performanceCategoryToLabel(PerformanceCategory.HighPerformance),
			color: performanceCategoryToColor(PerformanceCategory.HighPerformance),
			html: 'This is a competitive level of sport, where the focus of the environment is to develop elite athletes who can progress on to a higher competitive level, typically either post-secondary sport or professional sport.',
			progress: 60,
		},
		{
			level: PerformanceCategory.College,
			label: performanceCategoryToLabel(PerformanceCategory.College),
			color: performanceCategoryToColor(PerformanceCategory.College),
			html: 'This is a competitive level of sport, where the focus of the environment is to develop elite student athletes who may progress on to a professional career.',
			progress: 60,
		},
		{
			level: PerformanceCategory.ProAcademy,
			label: performanceCategoryToLabel(PerformanceCategory.ProAcademy),
			color: performanceCategoryToColor(PerformanceCategory.ProAcademy),
			html: 'This competitive level is the youth team of a professional club, whose aim is to develop players to play for the senior or adult team in the club.',
			progress: 80,
		},
		{
			level: PerformanceCategory.Professional,
			label: performanceCategoryToLabel(PerformanceCategory.Professional),
			color: performanceCategoryToColor(PerformanceCategory.Professional),
			html: 'This competitive level is a professional club, competing at the professional level.',
			progress: 80,
		},
		{
			level: PerformanceCategory.International,
			label: performanceCategoryToLabel(PerformanceCategory.International),
			color: performanceCategoryToColor(PerformanceCategory.International),
			html: 'This is the highest competitive level for sport. International athletes represent their country in youth and senior international competitions.',
			progress: 100,
		},
	];
}
