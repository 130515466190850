
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, StringsMixin, AthleteApiMixin, AthleteTeamScheduleMixin, SocialMediaMixin, BAIconsMixin, AthleteRoutingMixin } from "@/mixins";
import { TeamModel } from "@/models/team";

import TeamMiniCard from "@/components/ui/TeamMiniCard.vue";
import RecruitingProfileInfoProvider from "@/components/hoc/RecruitingProfileInfoProvider.vue";
import ShareableProfileInfo from "@/components/profile/recruiting/ShareableProfileInfo.vue"
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";

import AthleteHighlightReelCard from "@/components/profile/athlete/AthleteHighlightReelCard.vue";
import AthleteScheduleCard from "@/components/profile/athlete/AthleteScheduleCard.vue";
import AthleteVerifiedAssessmentsCard from "@/components/profile/athlete/AthleteVerifiedAssessmentsCard.vue";
import AthleteMindsetReportCard from "@/components/profile/athlete/AthleteMindsetReportCard.vue";
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";
import AthleteSelfAssessmentsCard from "@/components/profile/athlete/AthleteSelfAssessmentsCard.vue";
import AthleteEducationCard from "@/components/profile/athlete/AthleteEducationCard.vue";
import { athleteProfile as athleteProfileStore, notificationStore } from '@/store';

import { AthleteTeamDashboard, AthleteComparativeDataSummary, TeamDashboard } from "@/../types/constants/web_client_user.routes";
import { AthleteMetric } from '@best-athletes/ba-types';

@Component({
	components: {
		ShareableProfileInfo,
		RecruitingProfileInfo,
		RecruitingProfileInfoProvider,
		TeamMiniCard,		
		AthleteHighlightReelCard,
		AthleteScheduleCard,
		AthleteVerifiedAssessmentsCard,
		AthleteMindsetReportCard,
		AthleteScoutingReportsCard,
		AthleteSelfAssessmentsCard,
		AthleteEducationCard,
	}
})
export default class RecruitingProfileCard extends Mixins(VuetifyMixin, AppHostnameMixin, StringsMixin, AthleteRoutingMixin, AthleteApiMixin, AthleteTeamScheduleMixin, SocialMediaMixin, BAIconsMixin) {
	// inherited from AthleteTeamScheduleMixin
	// @Prop({ required: true }) athleteProfile: AthleteProfileModel;
	@Prop({ default: null }) sharingUrlId: string | null;
	
	showHelp: boolean = false;

	get IsAthleteViewHelp(): boolean {
		return this.AthleteAppActive && this.showHelp;
	}
	get ShowEducation(): boolean {
		if( this.IsAthleteViewHelp ) return true;
		return( this.athleteProfile.hasEducation && this.athleteProfile.isVisibleEducation )
	}
	get ShowHighlights(): boolean {
		if( this.IsAthleteViewHelp ) return true;
		return( this.athleteProfile.isVisible('highlightDetails') && this.athleteProfile.hasVideos )
	}
	get ShowSchedule(): boolean {
		if( !this.teamEventsInitialized ) return false;
		if( this.IsAthleteViewHelp ) return true;
		if( !this.athleteProfile.isVisible('schedule') ) return false;
		return( this.athleteProfile.hasFutureSchedule() || this.HasFutureTeamEvents )
	}
	ShowData(key: string, dataAvailable: boolean): boolean {
		if( this.IsAthleteViewHelp ) return true;
		return( dataAvailable && this.athleteProfile.isVisible(key));
	}
	
	onEdit(step: string) {
		this.$emit('editmode', step);
	}

	viewTeam(teamId: string) {
		if( this.AthleteAppActive ) {
			this.$router.push({
				name: AthleteTeamDashboard,
				params: {
					teamId
				}
			});
		} else {
			this.$router.push({
				name: TeamDashboard,
				params:{
					teamId,
				}			
			})
		}
	}	

	onViewComparativeData(metric: AthleteMetric): void{
		// view the comparative data for the specified metric for this athlete
		this.$router.push({
			name: AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.athleteProfile.id,
			}
		});
		// go to the top of the page
		this.$vuetify.goTo(0);
	}

	async updateProfileVisibility(setting: string) {
		this.athleteProfile.profileVisibility.recruiter[setting] = true;
		await athleteProfileStore.patchAthleteProfile({
				patch: {
					id: this.athleteProfile.id,
					profileVisibility: this.athleteProfile.profileVisibility,
				},
			});
		notificationStore.snackbarChangesSavedSuccess();
	}
}
