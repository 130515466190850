
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { AppHostnameMixin, BAIconsMixin, ColorMixin } from '@/mixins';

@Component
export default class ScoutingScoreSummary extends Mixins(BAIconsMixin, AppHostnameMixin, ColorMixin){
	@Prop() scoutingReport: ScoutingReportModel;
	@Prop() category: string;
	@Prop({type: Boolean}) blurred;

	get Score(): number {
		if( !this.scoutingReport ) return 0;
		return this.scoutingReport.evaluationMatrix[this.category];
	}
	get ScoreText(): string {
		if( this.Score > 4.9 ) return '4+';
		return this.Score.toFixed(0);
	}
	get Notes(): string {
		if( !this.scoutingReport ) return '';
		return this.scoutingReport.GetNote(this.category);
	}

}
